// @flow
import * as React from "react";
import { useEffect } from "react";
import { ChatBubble } from "../ChatBubble";
import { Answers } from "../Answers";
import { JSON_CONTENT_TYPE } from "../ChatForm/ChatForm";
import { useForm } from "react-hook-form";
import { MobileGPTMenu } from "../MobileGPTMenu";
import { DesktopGPTMenu } from "../DesktopGPTMenu";
import { API_URL } from "../../helpers/constants";
import { scrollPageDown } from "../ChatForm/scrollPageDown";
import ReactGA from "react-ga4";

type GptFormProps = {
  chatID: number;
  answersPrompts: string;
  onGPTStart: () => void;
  onTokensChange?: (tokensMax: number, tokensLeft: number) => void;
  wrapperRef: React.RefObject<HTMLFormElement>;
};

export function GptForm({
  chatID,
  answersPrompts,
  onGPTStart,
  onTokensChange,
  wrapperRef,
}: GptFormProps) {
  const { register, formState, getValues } = useForm();
  const [gptAnswers, setGptAnswers] = React.useState<any[]>([]);
  const [gptJson, setGptJson] = React.useState<any[]>([
    {
      id: 1,
      question: {
        title: "Môžem vám poradiť, ako zlepšiť zdravie vašej pečene?",
        text: "Po slovensky sa ešte len učím, preto občas robím chyby. Dúfam, že so mnou budete mať trpezlivosť.",
        image: "welcome_image",
      },
      answers: [
        {
          type: "button",
          text: "Áno",
          key: "ano",
          score: null,
          value: "start",
        },
        {
          type: "button",
          text: "Nie",
          key: "nie",
          score: null,
          value: "end",
        },
      ],
    },
  ]);
  const [gptBufferedText, setGptBufferedText] = React.useState<string>("");
  const [gptStarted, setGptStarted] = React.useState<boolean>(false);
  const [gptTokens, setGptTokens] = React.useState<{
    max: number;
    left: number;
  }>({
    max: 1000,
    left: 0,
  });
  const [gptFinished, setGptFinished] = React.useState<boolean>(false);
  const [gptWaitingForAnswer, setGptWaitingForAnswer] =
    React.useState<boolean>(false);
  const [gptEnded, setGptEnded] = React.useState<boolean>(false);
  const [tokensOut, setTokensOut] = React.useState<boolean>(false);

  const [finalLoading, setFinalLoading] = React.useState<boolean>(false);

  const handleGPTAnswers = (answer: number | string, id: number) => {
    setGptAnswers((prev) => [
      ...prev,
      {
        id,
        answer,
      },
    ]);

    const start = answer === "start";
    const end = answer === "end";

    if (start) {
      onGPTStart();
      setGptStarted(true);
      ReactGA.event({
        category: "Chat",
        action: "GPT Start",
        label: "GPT Start",
      });
    }

    if (end) {
      setGptEnded(true);
      setFinalLoading(true);

      ReactGA.event({
        category: "Chat",
        action: "GPT End",
        label: "GPT End",
      });

      setTimeout(() => {
        setFinalLoading(false);
      }, 2000);

      setTimeout(() => {
        scrollPageDown(wrapperRef);
      }, 100);
      return;
    }

    //setGptLoading(true);

    setGptJson((prev) => [
      ...prev,
      {
        id: prev.length + 1,
        loading: true,
        question: {
          title: "",
        },
        answers: [
          {
            type: "input",
            text: "chat-" + prev.length + 1,
            key: "chat",
          },
        ],
      },
    ]);

    const response = fetch(`${API_URL}/gpt`, {
      method: "POST",
      headers: JSON_CONTENT_TYPE,
      body: JSON.stringify({
        id: chatID,
        chat: start ? answersPrompts : answer,
        role: "user",
        start: start,
      }),
    });

    response
      .then((data) => {
        //setGptLoading(false);
        return data.json();
      })
      .then((data) => {
        setGptTokens({
          max: parseInt(data.token_limit),
          left: data.token_remaining,
        });

        const source = fetch(`${API_URL}/gpt/stream-response/${data.id}`);

        setGptWaitingForAnswer(false);

        source.then((data) => {
          // Body is a readable stream.
          const reader = data.body?.getReader();
          if (!reader) {
            return;
          }

          reader.read().then(function processText({ done, value }): any {
            if (done) {
              setGptFinished(true);
              setGptWaitingForAnswer(true);
              return;
            }

            // value for fetch streams is a Uint8Array
            const chunk = value;
            const decoder = new TextDecoder("utf-8");
            const text = decoder.decode(chunk);
            setGptBufferedText((prev) => prev + text);

            // process the text chunk by chunk
            // call processText recursively until we are done
            return reader.read().then(processText);
          });
        });
      });
  };

  useEffect(() => {
    if (gptBufferedText === "") {
      return;
    }

    // Add buffered text to last question
    setGptJson((prev) => {
      const lastQuestion = prev[prev.length - 1];
      lastQuestion.loading = false;
      lastQuestion.question.text = gptBufferedText;

      return [...prev];
    });

    scrollPageDown(wrapperRef);
  }, [gptBufferedText, wrapperRef]);

  useEffect(() => {
    if (!gptFinished) {
      return;
    }

    import("gpt-tokenizer/esm/model/gpt-4")
      .then(({ encode }) => {
        return encode(gptBufferedText);
      })
      .then((tokens: number[]) => {
        fetch(`${API_URL}/gpt`, {
          method: "POST",
          headers: JSON_CONTENT_TYPE,
          body: JSON.stringify({
            id: chatID,
            chat: gptBufferedText,
            role: "assistant",
            start: false,
          }),
        })
          .then((data) => {
            return data.json();
          })
          .catch((error) => {
            console.error("An error occurred while posting chat data", error);
          });

        fetch(`${API_URL}/gpt-tokens`, {
          method: "POST",
          headers: JSON_CONTENT_TYPE,
          body: JSON.stringify({
            id: chatID,
            tokens: tokens.length,
          }),
        })
          .then((data) => {
            return data.json();
          })
          .then((data) => {
            if (data.id === chatID) {
              setGptFinished(false);
              setGptBufferedText("");
              setGptTokens({
                max: parseInt(data.token_limit),
                left: data.token_remaining,
              });

              if (data.token_remaining <= 0) {
                setTokensOut(true);
                setGptEnded(true);
                setFinalLoading(true);

                ReactGA.event({
                  category: "Chat",
                  action: "GPT End tokens",
                  label: "GPT End tokens",
                });

                setTimeout(() => {
                  setFinalLoading(false);
                }, 2000);

                setTimeout(() => {
                  scrollPageDown(wrapperRef);
                }, 100);
              }
            }
          });

        scrollPageDown(wrapperRef);
      });
  }, [gptFinished, chatID, gptBufferedText, wrapperRef]);

  return (
    <>
      {gptStarted && (
        <DesktopGPTMenu
          actualStep={gptTokens.left}
          maxSteps={gptTokens.max}
          onStopChat={() => {
            setGptEnded(true);
            setTimeout(() => {
              scrollPageDown(wrapperRef);
            }, 100);
          }}
        />
      )}

      {gptJson.map((row: any, index: number) => {
        return (
          <div
            key={row.id}
            className="flex h-auto flex-col justify-between gap-4"
          >
            <ChatBubble
              loading={row.loading}
              title={row.question.title}
              text={row.question.text}
              imageName={row.question.image}
            />

            {/* If gptEnded is true hide last answer */}
            {gptEnded && index === gptJson.length - 1 ? null : (
              <>
                {gptWaitingForAnswer ||
                index === 0 ||
                gptAnswers.some((answer) => answer.id === row.id) ? (
                  <Answers
                    id={row.id}
                    answered={
                      gptAnswers.find((answer) => answer.id === row.id)?.answer
                    }
                    answers={row.answers}
                    onAnswer={handleGPTAnswers}
                    getValues={getValues}
                    formState={formState}
                    register={register}
                  />
                ) : null}
              </>
            )}
          </div>
        );
      })}

      {tokensOut && gptEnded && (
        <div className="mt-4 flex h-full flex-col justify-between gap-4">
          <ChatBubble
            loading={finalLoading}
            title={"Mrzí ma to, dosiahli sme limit."}
            text={
              "Ďakujem za rozhovor a dúfam, že som vám pomohol nájsť spôsoby, ako žiť zdravšie."
            }
            imageName="end_image"
          />
        </div>
      )}

      {gptEnded && (
        <div className="mt-4 flex h-full flex-col justify-between gap-4">
          <ChatBubble
            loading={finalLoading}
            title={"Každý štvrtý človek má stukovatenú pečeň."}
            text={
              "Pomôžte svojim priateľom zistiť ich riziko. Zdieľajte s nimi tento test. Vaše výsledky neuvidia. Teraz sa už musím ísť venovať ďalšiemu klientovi. Ďakujem za rozhovor a dúfam, že som vám pomohol, ako žiť zdravšie."
            }
            share
          />
        </div>
      )}

      {gptStarted && (
        <MobileGPTMenu
          onStopChat={() => {
            setGptEnded(true);
            setTimeout(() => {
              scrollPageDown(wrapperRef);
            }, 100);
          }}
          actualStep={gptTokens.left}
          maxSteps={gptTokens.max}
        />
      )}
    </>
  );
}
