// @flow
import * as React from "react";
import { useEffect } from "react";
import { ChatBubble } from "../ChatBubble";
import { Answers } from "../Answers";
import { useForm } from "react-hook-form";
import { Result } from "../Result";
import { MobileFormMenu } from "../MobileFormMenu";
import { DesktopFormMenu } from "../DesktopFormMenu";
import { GptForm } from "../GPTForm/GPTForm";
import { scrollPageDown } from "./scrollPageDown";
import { postChatData } from "./postChatData";
import { FAKE_LOADING_TIME } from "../../helpers/constants";
import ReactGA from "react-ga4";

export type ChatFormProps = {
  formJson: any;
};

export type FormDataType = {
  email: string;
  chat: any;
  // Add other form fields as needed
};

export const JSON_CONTENT_TYPE = { "Content-Type": "application/json" };

export function ChatForm({ formJson }: ChatFormProps) {
  const [chatID, setChatID] = React.useState<number>(0);
  const [answers, setAnswers] = React.useState<any[]>([]);
  const [answersPrompts, setAnswersPrompts] = React.useState<string>("");
  const [step, setStep] = React.useState(1);
  const [gdpr, setGdpr] = React.useState(false);
  const { register, formState, getValues, setValue, reset } = useForm({
    mode: "all",
    criteriaMode: "all",
    delayError: 2000,
  });
  const [submitted, setSubmitted] = React.useState(false);
  const [resultVariant, setResultVariant] = React.useState<
    "bad" | "medium" | "good"
  >();
  const wrapperRef = React.useRef<HTMLFormElement>(null);
  const [gptStarted, setGptStarted] = React.useState<boolean>(false);

  const calculateResult = () => {
    const result = answers.reduce((acc, answer) => {
      if (answer.score) {
        return acc + answer.score;
      }

      return acc;
    }, 0);

    if (result <= 3) {
      setResultVariant("good");
      return "nizke";
    } else if (result >= 4 && result <= 7) {
      setResultVariant("medium");
      return "stredne";
    } else {
      setResultVariant("bad");
      return "vysoke";
    }
  };

  const handleSubmitForm = (data: FormDataType) => {
    const result = calculateResult();

    postChatData(data, result)
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        setChatID(data.id);
        setSubmitted(true);
      })
      .finally(() => {
        // wait 100ms for the chat to be submitted
        setTimeout(() => {
          scrollPageDown(wrapperRef);
        }, 100);
      })
      .catch((error) => {
        console.error("An error occurred while posting chat data", error);
      });
  };

  const toCapital = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleAnswers = (
    answer: number | string,
    id: number,
    key: string,
    score: number | null,
    prompt?: string,
  ) => {
    ReactGA.event({
      category: "Chat",
      action: toCapital(key),
      label: key,
      value: typeof answer === "number" ? answer : undefined,
    });

    if (key === "gdpr-nesuhlas" && answer === "nie") {
      // Repeat the same step until the user agrees with the GDPR
      setStep((prev) => prev);

      return;
    }

    setAnswers((prev) => [
      ...prev,
      {
        id,
        answer,
        score,
      },
    ]);

    // Check if the key is "gdpr" and if the answer is "yes" then skip the next step else continue
    if (key === "gdpr" && answer === "ano") {
      setGdpr(true);
      setStep(step + 2);
      return;
    }

    // Check by key if the answer is in the useForm
    const values = getValues();
    if (!values[key]) {
      setValue(key, answer);
    }

    if (prompt) {
      setAnswersPrompts((prev) => prev + "|" + prompt);
    }

    setStep(step + 1);

    if (key === "email") {
      handleSubmitForm({
        email: getValues().email,
        chat: getValues(),
      });
    }
  };

  // Update step logic to skip if necessary
  useEffect(() => {
    if (formJson[step]?.sex && formJson[step]?.sex !== getValues("pohlavie")) {
      setStep((step) => step + 1);
    }

    scrollPageDown(wrapperRef);

    setTimeout(() => {
      scrollPageDown(wrapperRef);
    }, FAKE_LOADING_TIME + 400);
  }, [formJson, step, getValues]);

  const handleOnGPTStart = () => {
    setGptStarted(true);
  };

  useEffect(() => {
    if (formState.errors) {
      scrollPageDown(wrapperRef);
    }
  }, [formState]);

  return (
    <>
      {!gptStarted && (
        <DesktopFormMenu actualStep={step} maxSteps={formJson.length} />
      )}

      <form
        ref={wrapperRef}
        className="flex flex-col gap-4 overflow-y-scroll px-5 pb-16 pt-7 sm:px-[50px] lg:h-full lg:pb-[150px] lg:pt-0"
        onSubmit={(e) => e.preventDefault()}
      >
        {formJson.map((row: any, index: number) => {
          if (gdpr && row.type === "gdpr-nesuhlas") {
            return null;
          }

          if (row.sex && row.sex !== getValues("pohlavie")) {
            return null;
          }

          if (index >= step) {
            return null;
          }

          return (
            <div
              key={row.id}
              className="flex h-auto flex-col justify-between gap-4"
            >
              <ChatBubble
                title={row.question.title}
                text={row.question.text}
                imageName={row.question.image}
                className={index === 0 ? "lg:mt-20" : ""}
              />
              <Answers
                id={row.id}
                register={register}
                answered={
                  answers.find((answer) => answer.id === row.id)?.answer
                }
                answers={row.answers}
                onAnswer={handleAnswers}
                formState={formState}
                getValues={getValues}
              />
            </div>
          );
        })}

        {submitted && (
          <div className="flex flex-col gap-4">
            <Result variant={resultVariant} />
          </div>
        )}

        {submitted && (
          <GptForm
            chatID={chatID}
            answersPrompts={answersPrompts}
            onGPTStart={handleOnGPTStart}
            wrapperRef={wrapperRef}
          />
        )}
      </form>

      {!gptStarted && (
        <MobileFormMenu
          onRestart={() => {
            setAnswers([]);
            setStep(1);
            setGdpr(false);
            setSubmitted(false);
            setResultVariant(undefined);
            reset();
          }}
          actualStep={step}
          maxSteps={formJson.length}
        />
      )}
    </>
  );
}
