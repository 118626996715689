import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

// Todo: before deploy, change to G-54FMHMPW3Q
ReactGA.initialize("G-54FMHMPW3Q"); //  G-54FMHMPW3Q = ostrý / G-PS07EKSWER = testovací

ReactPixel.init("335165705877887", undefined, {
  autoConfig: true,
  debug: false,
});

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
